import "./Tides.css";
import React, { useState } from "react";
import Loading from "../design-system/Loading";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import palmierMarker from "../../design/icons/coconut.png";
import { isMobile } from "react-device-detect";
import { useTidesDatas } from "../../hooks/useTidesDatas";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";

// Type de données pour une marée
type Tide = {
  type: string; // Utilisez string car les données initiales peuvent ne pas correspondre
  time: string;
  height: string;
  coef: string;
};

// Type pour une journée de marées
type TideDay = {
  date: string;
  tides: Tide[];
  moon_phase: string;
  saint: string;
  sunrise: string;
  sunset: string;
};

const Tides: React.FC = () => {
  const { tideDay, tideWeek } = useTidesDatas(); // Utilisation du hook pour récupérer les données
  const [expanded, setExpanded] = useState<boolean>(false);
  const [selectedDay, setSelectedDay] = useState<string>("");

  // Utilisation du premier jour par défaut si tideWeek est disponible
  React.useEffect(() => {
    if (tideWeek.length > 0) {
      setSelectedDay(tideWeek[0].date); // Sélectionne le premier jour de la semaine
    }
  }, [tideWeek]);

  // const handleDateChange = (event: React.ChangeEvent<{ value: unknown }>) => {
  //   setSelectedDay(event.target.value as string);
  // };

  const handleDateChange = (event: SelectChangeEvent<string>) => {
    setSelectedDay(event.target.value);
  };

  // Fonction pour obtenir l'emoji basé sur le type de marée
  const getEmoji = (type: string) => {
    return type === "Marée haute" ? "↗️" : "↘️";
  };

  // Obtenir les marées du jour sélectionné
  const getTidesForSelectedDay = () => {
    if (!selectedDay) return [];

    const selectedTideDay = tideWeek.find((day) => day.date === selectedDay);
    return selectedTideDay ? selectedTideDay.tides : [];
  };

  return (
    <>
      {isMobile && (
        <Box>
          <Paper
            elevation={3}
            className="tides-container"
            sx={{
              height: expanded ? "auto" : "86px",
              transition: "height 0.3s ease",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {!tideDay ? (
              // Utilisation d'un indicateur de chargement si les données ne sont pas encore disponibles
              <Loading />
            ) : (
              <Box
                onClick={() => setExpanded(!expanded)}
                sx={{
                  fontSize: "22px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                  cursor: "pointer",
                  padding: "10px",
                  fontFamily: "Roboto, sans-serif",
                }}
              >
                <img
                  src={palmierMarker}
                  style={{ height: "3em", width: "3em" }}
                  alt="Coconut Icon"
                />
                <h1>Marées</h1>

                <IconButton>
                  {expanded ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </Box>
            )}

            {expanded && tideDay && (
              // Affichage de la liste des marées pour aujourd'hui
              <>
                <div
                  style={{
                    padding: "20px",
                    textAlign: "center",
                    width: "500px",
                    height: "fit-content",
                  }}
                >
                  <Select
                    value={selectedDay}
                    onChange={handleDateChange}
                    className="custom-select"
                    fullWidth
                    variant="outlined"
                    sx={{
                      fontSize: "30px !important", // Ajustez la taille de la police ici
                      fontWeight: "500",
                      borderRadius: "8px !important", // Coins arrondis
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // Ombre douce
                      padding: "12px 16px !important", // Espacement interne
                      backgroundColor: "#ffffff", // Fond blanc
                    }}
                  >
                    {tideWeek.map((day, index) => (
                      <MenuItem
                        key={index}
                        value={day.date}
                        sx={{ fontSize: "30px !important" }}
                      >
                        {day.date}
                      </MenuItem>
                    ))}
                  </Select>
                </div>

                <List>
                  {getTidesForSelectedDay()
                    .filter(
                      (tide) =>
                        tide.type === "Marée haute" ||
                        tide.type === "Marée basse"
                    )
                    .map((tide, index) => (
                      <ListItem key={index} className="tide-list-item">
                        <ListItemIcon>{getEmoji(tide.type)}</ListItemIcon>
                        <ListItemText
                          primaryTypographyProps={{ sx: { fontSize: "28px" } }}
                          primary={`${tide.type} à ${tide.time} - ${tide.height}m (coef: ${tide.coef})`}
                        />
                      </ListItem>
                    ))}
                </List>
              </>
            )}
          </Paper>
        </Box>
      )}
    </>
  );
};

export default Tides;
