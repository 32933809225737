import "./DrawerButton.css";

import React from "react";
import { isMobile } from "react-device-detect";

interface DrawerButtonProps {
  buttonText: string;
  onClick: (event: any) => void;
}

const DrawerButton = ({ buttonText, onClick }: DrawerButtonProps) => {
  return (
    <div
      className={`${
        isMobile ? "drawer-button-container" : "drawer-button-container-desktop"
      }`}
      onClick={onClick}
    >
      {buttonText}
    </div>
  );
};

export default DrawerButton;
