import React, { useState } from "react";
import "./WindSort.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { isMobile } from "react-device-detect";
import { Beach } from "../../types/Beach";
import BeachMarker from "../beach/BeachMarker";

interface WindSortProps {
  currentDirection: string;
  beaches: Beach[];
  onClose: () => void;
  handleWindSortDisplayBeaches: (beach: Beach | undefined) => void;
}

const WindSort = ({
  currentDirection,
  beaches,
  onClose,
  handleWindSortDisplayBeaches,
}: WindSortProps) => {
  const getWindSortedBeach = () => {
    const currentDirectionFirstLetter = currentDirection.charAt(0);
    const beachScores = beaches.map((beach) => {
      let score = 0;
      const beachDirection = beach.direction.substring(0, 2);
      const oppositeDirections: { [key: string]: string } = {
        N: "S",
        S: "N",
        E: "W",
        W: "E",
        NE: "SW",
        NW: "SE",
        SE: "NW",
        SW: "NE",
      };

      if (oppositeDirections[currentDirectionFirstLetter] === beachDirection) {
        score = 2;
      } else if (currentDirectionFirstLetter === beachDirection) {
        score = 1;
      }

      return { beach, score };
    });

    const sortedBeaches = beachScores
      .sort((a, b) => b.score - a.score)
      .map((item) => item.beach);

    return sortedBeaches;
  };

  const handleBeachClick = (beach: Beach | undefined) => {
    handleWindSortDisplayBeaches(beach);
    onClose();
  };

  return (
    <div className={`windsort-container${isMobile ? "" : "-desktop"}`}>
      <h2 className={`windsort-title${isMobile ? "" : "-desktop"}`}>
        Plages à l'abris du vent
      </h2>
      <p className={`windsort-subtitle${isMobile ? "" : "-desktop"}`}>
        Classement selon l'orientation des plages
      </p>
      <ul className="windsort-content">
        {getWindSortedBeach().map((beach, index) => (
          <li
            key={beach.name}
            className="beach-sorted"
            onClick={() => {
              handleBeachClick(beach);
            }}
          >
            <div className="beach-index">{index + 1}</div>
            <div className={`beach-sorted-photo${isMobile ? "" : "-desktop"}`}>
              <img
                className={`beach-sorted-photo${isMobile ? "" : "-desktop"}`}
                src={beach.image}
                alt={beach.name}
              />
            </div>
            <div
              className={`beach-sorted-text${
                isMobile
                  ? " beach-sorted-name"
                  : "-desktop beach-sorted-name-desktop"
              }`}
            >
              {beach.name}
            </div>
          </li>
        ))}
      </ul>
      <div
        className={`${isMobile ? "close-button" : "close-button-desktop"}`}
        onClick={onClose}
      >
        <FontAwesomeIcon icon={faTimes} />
      </div>
    </div>
  );
};

export default WindSort;
