import React, { useEffect, useState } from "react";
import Loading from "../design-system/Loading";
import { Beach } from "../../types/Beach";
import { apiKey } from "../../weather-api/api-key";

interface WeatherProps {
  beach: Beach;
}

// TODO: rename this BeachWeather
// TODO: we should regroup API call in one place
const BeachWeather = ({ beach }: WeatherProps) => {
  const [windSpeed, setWindSpeed] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Promise.all([
      fetch(
        `https://api.weatherapi.com/v1/current.json?key=${apiKey}&q=${beach.gps[0]},${beach.gps[1]}`
      ),
    ])
      .then((responses) =>
        Promise.all(responses.map((response) => response.json()))
      )
      .then((data) => {
        const [beachData] = data;

        setWindSpeed(beachData.current.wind_kph);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching weather data:", error);
        setLoading(false);
      });
  }, [apiKey, beach.gps]);

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <>{windSpeed !== null && <p>⚠️ Vent : {windSpeed} km/h</p>}</>
      )}
    </div>
  );
};

export default BeachWeather;
