import React, { createContext, useState, ReactNode } from "react";

// Définir les types pour le contexte
interface CriteriaContextType {
  selectedCriteria: string[];
  updateCriteria: (criteria: string) => void;
}

// Créer le contexte avec les types appropriés
const CriteriaContext = createContext<CriteriaContextType | undefined>(
  undefined
);

// Définir les types pour les props du fournisseur
interface CriteriaProviderProps {
  children: ReactNode;
}

const CriteriaProvider = ({ children }: CriteriaProviderProps) => {
  const [selectedCriteria, setSelectedCriteria] = useState<string[]>([]);

  const updateCriteria = (criteria: string) => {
    setSelectedCriteria((prevCriteria) => {
      if (prevCriteria.includes(criteria)) {
        return prevCriteria.filter((c) => c !== criteria);
      } else {
        return [...prevCriteria, criteria];
      }
    });
  };

  const contextValue: CriteriaContextType = {
    selectedCriteria,
    updateCriteria,
  };

  return (
    <CriteriaContext.Provider value={contextValue}>
      {children}
    </CriteriaContext.Provider>
  );
};

export { CriteriaContext, CriteriaProvider };
