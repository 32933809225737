import React from "react";
import "./BeachPopup.css";
import { isMobile } from "react-device-detect";
import { Beach } from "../../types/Beach";
import BeachWeather from "./BeachWeather";
import { Chip } from "@mui/material";
import volleyMarker from "../../design/markers/volley-marker.png";

interface BeachPopupProps {
  beach: Beach;
}

const BeachPopup = ({ beach }: BeachPopupProps) => {
  return (
    <div className={`${isMobile ? "popup" : "popup-desktop"}`}>
      <div className={`${isMobile ? "popup-photo" : "popup-photo-desktop"}`}>
        <img
          className={`${isMobile ? "popup-photo" : "popup-photo-desktop"}`}
          src={beach.image}
          alt="Beach"
        />
      </div>
      <div className={`${isMobile ? "popup-text" : "popup-text-desktop"}`}>
        <div className={`${isMobile ? "beach-name" : "beach-name-desktop"}`}>
          {beach.name}
        </div>
        <div
          className={`${
            isMobile ? "beach-description" : "beach-description-desktop"
          }`}
        >
          <BeachWeather beach={beach} />
        </div>
        <div className="chips-container">
          {beach.criteria.map((criterion) =>
            isMobile ? (
              <Chip
                key={criterion}
                label={criterion}
                size="medium"
                sx={{
                  fontSize: "1.6rem",
                  height: "48px",
                  margin: "0.2em",
                  padding: "0 12px",
                }}
              />
            ) : (
              <Chip
                key={criterion}
                label={criterion}
                size="small"
                sx={{
                  margin: "0.2em",
                  padding: "0 12px",
                }}
              />
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default BeachPopup;
