import React, { useRef, useState } from "react";
import ReactMapGL, { MapRef } from "react-map-gl";
import "./Map.css";
import BeachMarker from "../components/beach/BeachMarker";
import RecenterButton from "../components/design-system/RecenterButton";
import { Beach } from "../types/Beach";
import { beaches } from "../components/beach/BeachConfig";

interface MapProps {
  sortedBeaches: Beach[];
  displayBeaches: boolean;
  windSortDisplayBeaches: Beach | undefined;
  setShowLandingPage: React.Dispatch<React.SetStateAction<boolean>>;
}

const Map = ({
  sortedBeaches,
  displayBeaches,
  windSortDisplayBeaches,
  setShowLandingPage,
}: MapProps) => {
  const [selectedBeach, setSelectedBeach] = useState<Beach | null>(null);
  const [showRecenterButton, setShowRecenterButton] = useState<boolean>(false);
  const initialCenter = [46.71, -2.34];

  const handleMarkerClick = (beach: Beach) => {
    setSelectedBeach(beach);
  };

  const TOKEN =
    "pk.eyJ1Ijoiem9lYm9ybnkiLCJhIjoiY2xrZnZnOHJoMG14cTNnbzU5bmU4YnJwYSJ9.mQVtY5FXmAbfBbZ_rUmD2w";

  const MAP_STYLE = "mapbox://styles/zoeborny/clqxtwnki014s01nwgb5l1kf3";

  const mapRef = useRef<MapRef>(null);

  const goToInitialLocation = () => {
    if (mapRef.current) {
      const map = mapRef.current.getMap();
      map.flyTo({
        center: [-2.34, 46.71],
        zoom: 12.45,
      });
    }
  };

  const conditionSortedBeach =
    sortedBeaches.filter((beach) => beach.compatibilityPercentage > 0).length >
    0;

  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      <ReactMapGL
        ref={mapRef}
        initialViewState={{
          latitude: initialCenter[0],
          longitude: initialCenter[1],
          zoom: 12.45,
        }}
        mapboxAccessToken={TOKEN}
        mapStyle={MAP_STYLE}
        attributionControl={false}
        onDrag={() => setShowRecenterButton(true)}
        onLoad={() => {
          setShowLandingPage(false);
        }}
      >
        {sortedBeaches
          .filter((beach) => beach.compatibilityPercentage > 0)
          .map((beach, index) => (
            <BeachMarker
              key={index}
              beach={beach}
              onClick={() => handleMarkerClick(beach)}
              isSelected={selectedBeach === beach}
            />
          ))}
        {displayBeaches &&
          !conditionSortedBeach &&
          beaches.map((beach, index) => {
            return (
              <BeachMarker
                key={beach.name}
                beach={beach}
                onClick={() => handleMarkerClick(beach)}
                isSelected={selectedBeach === beach}
                displayBeaches={displayBeaches}
              />
            );
          })}
        {windSortDisplayBeaches && !conditionSortedBeach && (
          <BeachMarker
            key={windSortDisplayBeaches.name}
            beach={windSortDisplayBeaches}
            onClick={() => handleMarkerClick(windSortDisplayBeaches)}
            isSelected={true}
            displayBeaches={displayBeaches}
          />
        )}

        <RecenterButton
          onClick={goToInitialLocation}
          show={showRecenterButton}
        />
      </ReactMapGL>
    </div>
  );
};

export default Map;
