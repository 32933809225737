import React, { useState } from "react";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Text from "../design-system/Text";
import DrawerButton from "./DrawerButton";
import CriteriaBox from "./CriteriaBox";
import { CriteriaStates } from "../../types/CriteriaStates";
import { Beach } from "../../types/Beach";

import alguesIcon from "../../design/icons/algues.png";
import drinkIcon from "../../design/icons/cocktail.png";
import handicapeIcon from "../../design/icons/handicape.png";
import surfIcon from "../../design/icons/surf.png";
import kayakIcon from "../../design/icons/kayak.png";
import volleyIcon from "../../design/icons/volley.png";

// Constants for drawer dimensions and styling
const DRAWER_BLEEDING = 250;
const SWIPE_AREA_WIDTH = 300;
const DRAWER_HEIGHT_OPEN = "60%";
const DRAWER_HEIGHT_CLOSED = "500px";

// Styled components for the drawer's layout and design
const Root = styled("div")(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "light"
      ? grey[100]
      : theme.palette.background.default,
}));

const StyledBox = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
  overflow: "hidden",
  position: "relative",
  cursor: "pointer",
  zIndex: 2,
}));

const Puller = styled("div")(({ theme }) => ({
  width: 60,
  height: 12,
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 6,
  position: "absolute",
  top: 20,
  left: "calc(50% - 30px)",
}));

// TypeScript interface for component props
interface MobileDrawerProps {
  window?: () => Window;
  criteriaStates: CriteriaStates;
  handleCriteriaClick: (title: string) => void;
  handleWindSortDisplay: () => void;
  handleDisplayBeaches: () => void;
  beaches: Beach[];
}

// Main MobileDrawer component
const MobileDrawer = ({
  window,
  criteriaStates,
  handleCriteriaClick,
  handleWindSortDisplay,
  handleDisplayBeaches,
}: MobileDrawerProps) => {
  const [open, setOpen] = useState(false);

  // Function to toggle the drawer's open state
  const toggleDrawer = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  // Handles clicks on the drawer header to toggle the drawer state
  const handleHeaderClick = () => {
    toggleDrawer(!open);
  };

  // Handles button clicks inside the drawer without stopping propagation
  const handleButtonClick = (callback: () => void) => {
    callback();
    setOpen(false);
  };

  // Handles criteria button clicks and passes the title to the callback
  const handleCriteriaButtonClick = (title: string) => {
    handleCriteriaClick(title);
    setOpen(false);
  };

  // Function to determine the container for the drawer
  const container = window ? () => window().document.body : undefined;

  return (
    <Root>
      <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            height: open ? DRAWER_HEIGHT_OPEN : DRAWER_HEIGHT_CLOSED,
            overflow: "visible",
          },
        }}
      />
      <SwipeableDrawer
        container={container}
        anchor="bottom"
        open={open}
        onClose={() => toggleDrawer(false)}
        onOpen={() => toggleDrawer(true)}
        swipeAreaWidth={SWIPE_AREA_WIDTH}
        disableSwipeToOpen={false}
        ModalProps={{ keepMounted: true }}
      >
        <StyledBox
          sx={{
            position: "absolute",
            visibility: "visible",
            right: 0,
            left: 0,
            top: -DRAWER_BLEEDING,
            height: "300px",
            borderTopLeftRadius: 50,
            borderTopRightRadius: 50,
          }}
          onClick={handleHeaderClick} // Toggles the drawer on header click
        >
          <Puller />
          <br />
          <br />
          <Text type="title" text="Quels sont vos critères ?" />
        </StyledBox>
        {/* Drawer content area */}
        <StyledBox sx={{ px: 2, pb: 2, height: "100%", overflow: "auto" }}>
          <CriteriaBox
            titleBox=""
            icon1={surfIcon}
            title1="Surf"
            icon2={volleyIcon}
            title2="Volley"
            icon3={handicapeIcon}
            title3="Handicapés"
            handleCriteriaClick={handleCriteriaButtonClick} // Pass the handleCriteriaButtonClick directly
          />
          <CriteriaBox
            titleBox=""
            icon1={drinkIcon}
            title1="Boissons"
            icon2={alguesIcon}
            title2="Algues"
            icon3={kayakIcon}
            title3="Nautiques"
            handleCriteriaClick={handleCriteriaButtonClick} // Pass the handleCriteriaButtonClick directly
          />
          <DrawerButton
            buttonText="Afficher les plages"
            onClick={() => handleButtonClick(handleDisplayBeaches)} // Handle button click
          />
          <DrawerButton
            buttonText="Plages à l'abris du vent"
            onClick={() => handleButtonClick(handleWindSortDisplay)} // Handle button click
          />
        </StyledBox>
      </SwipeableDrawer>
    </Root>
  );
};

export default MobileDrawer;
