import React, { useEffect, useState } from "react";

import windIconE from "../../design/icons/direction-e.png";
import windIconN from "../../design/icons/direction-n.png";
import windIconNE from "../../design/icons/direction-ne.png";
import windIconNW from "../../design/icons/direction-nw.png";
import windIconS from "../../design/icons/direction-s.png";
import windIconSE from "../../design/icons/direction-se.png";
import windIconSW from "../../design/icons/direction-sw.png";
import windIconW from "../../design/icons/direction-w.png";
import windIcon from "../../design/icons/vent_noir.png";
import { isMobile } from "react-device-detect";
import { apiKey } from "../../weather-api/api-key";
import { waterGPS, yeuGPS } from "../../gps-data/gps";

const GlobalData = () => {
  const [showGlobalData, setShowGlobalData] = useState(false);
  const [windDirection, setWindDirection] = useState<string>(String);
  const [windKPH, setWindKPH] = useState<string | number | null>(null);

  useEffect(() => {
    Promise.all([
      fetch(
        `https://api.weatherapi.com/v1/current.json?key=${apiKey}&q=${yeuGPS[0]},${yeuGPS[1]}`
      ),
      fetch(
        `https://api.weatherapi.com/v1/marine.json?key=${apiKey}&q=${waterGPS[0]},${waterGPS[1]}`
      ),
    ])
      .then((responses) =>
        Promise.all(responses.map((response) => response.json()))
      )
      .then((data) => {
        const [current] = data;

        setWindDirection(current.current.wind_dir);
        setWindKPH(current.current.wind_kph);
      })
      .catch((error) => {
        console.error(
          "Erreur lors de la récupération des données météorologiques :",
          error
        );
      });
  }, [apiKey, yeuGPS, waterGPS]);

  function getwindDirectionectionRotation(windDirection: string) {
    switch (windDirection) {
      case "N":
        return (
          <img src={windIconN} style={{ width: "25px", height: "25px" }} />
        );
      case "NNE":
      case "NE":
      case "EN":
      case "NEN":
      case "ENE":
        return (
          <img src={windIconNE} style={{ width: "25px", height: "25px" }} />
        );
      case "E":
        return (
          <img src={windIconE} style={{ width: "25px", height: "25px" }} />
        );
      case "SE":
      case "SSE":
      case "ES":
      case "SES":
      case "ESE":
        return (
          <img src={windIconSE} style={{ width: "25px", height: "25px" }} />
        );
      case "S":
        return (
          <img src={windIconS} style={{ width: "25px", height: "25px" }} />
        );
      case "SW":
      case "SSW":
      case "WS":
      case "SWS":
      case "WSW":
        return (
          <img src={windIconSW} style={{ width: "25px", height: "25px" }} />
        );
      case "W":
        return (
          <img src={windIconW} style={{ width: "25px", height: "25px" }} />
        );
      case "NW":
      case "NNW":
      case "WN":
      case "NWN":
      case "WNW":
        return (
          <img src={windIconNW} style={{ width: "25px", height: "25px" }} />
        );
      default:
        return null;
    }
  }

  function getWindColor() {
    if (windKPH && typeof windKPH === "number") {
      if (windKPH <= 30) {
        return "rgb(61, 255, 81)";
      } else if (windKPH <= 70) {
        return "#ffa700";
      } else {
        return "red";
      }
    }
  }

  return (
    <div
      className={`
        ${
          isMobile
            ? "global-data-card-mobile-open"
            : "global-data-card-desktop-open"
        }`}
      onClick={() => setShowGlobalData(!showGlobalData)}
    >
      <div className="title-wind">
        <img
          src={windIcon}
          className="global-data-content"
          style={{ width: "40px", height: "40px" }}
        />
        <div className="wind-kph">
          <p className="wind-kph-value">{windKPH}</p>
          <p className="wind-kph-unity">km/h</p>
        </div>
      </div>
      <div className="content-wind">
        <p>Vent</p>
        <div
          className="color-wind"
          style={{ backgroundColor: getWindColor() }}
        />
        <div>{getwindDirectionectionRotation(windDirection)}</div>
        <p>{windDirection}</p>
        {/* <p>{test}</p> */}
      </div>
    </div>
  );
};

export default GlobalData;
