import React from "react";
import recenterIcon from "../../design/icons/direction-sw.png";
import "./RecenterButton.css";
import { isMobile } from "react-device-detect";
interface RecenterButtonProps {
  onClick: () => void;
  show: boolean;
}

// TODO: move the map logic here, we shouldn't have props here.
const RecenterButton = ({ onClick, show }: RecenterButtonProps) => {
  return show ? (
    <div
      className={
        isMobile
          ? "recenter-button-container"
          : "recenter-button-container-desktop"
      }
      onClick={onClick}
    >
      <img src={recenterIcon} alt="Recentrer la carte" />
    </div>
  ) : null;
};

export default RecenterButton;
