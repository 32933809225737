import React, { useEffect, useState } from "react";
import logo from "../design/icons/logo-landing4.png";
import ErrorAlert from "../components/design-system/Alert";

const LandingPage = () => {
  const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);
  return (
    <div
      style={{
        backgroundColor: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100%",
        position: "fixed",
        zIndex: "1999",
      }}
    >
      {isOnline ? (
        <img src={logo} style={{ maxWidth: "500px", maxHeight: "500px" }} />
      ) : (
        <>
          <img src={logo} style={{ maxWidth: "500px", maxHeight: "500px" }} />
          <ErrorAlert />
        </>
      )}
    </div>
  );
};

export default LandingPage;
