import "mapbox-gl/dist/mapbox-gl.css";
import React, { useContext } from "react";
import { Marker } from "react-map-gl";
import cocoMarker from "../../design/icons/coconut.png";
import meduseMarker from "../../design/markers/meduse-marker.png";
import surfMarker from "../../design/markers/surf-marker.png";
import drinkMarker from "../../design/markers/drink-marker.png";
import algueMarker from "../../design/markers/algue-marker.png";
import swimMarker from "../../design/markers/swim-marker.png";
import handicapeMarker from "../../design/markers/handicape-marker.png";
import kayakIcon from "../../design/icons/kayak-marker.png";
import volleyMarker from "../../design/markers/volley-marker.png";
import { Beach } from "../../types/Beach";
import { CriteriaContext } from "../../context/CriteriaContext";
import BeachPopup from "./BeachPopup";
import { beaches } from "./BeachConfig";

interface BeachMarkerProps {
  beach: Beach;
  onClick: () => void;
  isSelected: boolean;
  displayBeaches?: boolean;
}

const BeachMarker = ({
  beach,
  onClick,
  isSelected,
  displayBeaches,
}: BeachMarkerProps) => {
  const context = useContext(CriteriaContext);

  if (!context) {
    throw new Error("BeachMarker must be used within a CriteriaProvider");
  }

  function renderMarkers(beach: Beach) {
    const markers = [];

    if (!Array.isArray(beach.criteria)) {
      console.error("beach.criteria doit être un tableau");
      return [cocoMarker];
    }

    const criteriaMarkerMap: { [key: string]: string } = {
      handicapés: handicapeMarker,
      méduses: meduseMarker,
      surf: surfMarker,
      boissons: drinkMarker,
      algues: algueMarker,
      swim: swimMarker,
      volley: volleyMarker,
      nautiques: kayakIcon,
    };

    for (const criterion of beach.criteria) {
      if (context?.selectedCriteria.includes(criterion)) {
        markers.push(criteriaMarkerMap[criterion]);
      }
    }

    if (markers.length === 0) {
      markers.push(cocoMarker);
    }

    return markers;
  }

  const markers = renderMarkers(beach);

  return (
    <>
      {displayBeaches ? (
        <Marker
          latitude={beach.gps[0]}
          longitude={beach.gps[1]}
          onClick={onClick}
        >
          <img
            key={beach.name}
            className={`marker-box`}
            src={cocoMarker}
            style={
              isSelected
                ? { width: "80px", height: "80px" }
                : { width: "60px", height: "60px" }
            }
            alt="Beach Marker"
          />
        </Marker>
      ) : (
        <Marker
          latitude={beach.gps[0]}
          longitude={beach.gps[1]}
          onClick={onClick}
        >
          {markers.map((marker, index) => {
            const size =
              marker === drinkMarker ||
              marker === volleyMarker ||
              marker === kayakIcon ||
              marker === cocoMarker
                ? { width: "60px", height: "60px" }
                : { width: "90px", height: "90px" };

            const sizeSelected =
              marker === drinkMarker ||
              marker === volleyMarker ||
              marker === cocoMarker
                ? { width: "80px", height: "80px" }
                : { width: "110px", height: "110px" };

            return (
              <img
                key={index}
                className={`marker-box`}
                src={marker}
                style={isSelected ? sizeSelected : size}
                alt="Beach Marker"
              />
            );
          })}
        </Marker>
      )}
      {isSelected && <BeachPopup beach={beach} />}
    </>
  );
};

export default BeachMarker;
