import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Alert, Box, Button } from "@mui/material";
import { isMobile } from "react-device-detect";

const ErrorAlert = () => {
  const [showAlert, setShowAlert] = useState(true);

  const handleClose = () => {
    setShowAlert(false);
  };

  return (
    <>
      {showAlert && (
        <Box
          sx={{
            zIndex: 1350,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          {isMobile ? (
            <Alert
              variant="outlined"
              severity="error"
              onClose={handleClose}
              sx={{
                zIndex: 200,
                background: "white",
                color: "black",
                borderRadius: "20px",
                width: {
                  xs: "90%", // 90% width on extra small screens
                  sm: "80%", // 80% width on small screens
                  md: "90% !important", // 60% width on medium screens
                  lg: "80%", // 40% width on large screens
                },
                maxWidth: "500px",
                fontSize: {
                  xs: "2.6em !important", // Larger font size on small screens
                  sm: "1.1em !important",
                  md: "2.6em !important",
                },
                textAlign: "center",
              }}
            >
              Vous n'êtes pas connecté à internet 🤒
            </Alert>
          ) : (
            <Alert
              variant="outlined"
              severity="error"
              onClose={handleClose}
              sx={{
                zIndex: 200,
                background: "white",
                color: "black",
                borderRadius: "20px",
                width: {
                  xs: "90%", // 90% width on extra small screens
                  sm: "80%", // 80% width on small screens
                  md: "90% !important", // 60% width on medium screens
                  lg: "80%", // 40% width on large screens
                },
                maxWidth: "500px",
                fontSize: {
                  md: "1.4em !important",
                },
                textAlign: "center",
              }}
            >
              Vous n'êtes pas connecté à internet 🤒
            </Alert>
          )}
        </Box>
      )}
    </>
  );
};

export default ErrorAlert;
