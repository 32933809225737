import React from "react";
import { isMobile } from "react-device-detect";
import DesktopDrawer from "./DesktopDrawer";
import MobileDrawer from "./MobileDrawer";
import { CriteriaStates } from "../../types/CriteriaStates";
import { beaches } from "../beach/BeachConfig";

interface DrawerProps {
  criteriaStates: CriteriaStates;
  handleWindSortDisplay: () => void;
  handleCriteriaClick: (criteria: string) => void;
  handleDisplayBeaches: () => void;
}

const Drawer = ({
  criteriaStates,
  handleWindSortDisplay,
  handleCriteriaClick,
  handleDisplayBeaches,
}: DrawerProps) => {
  return (
    <>
      {isMobile ? (
        <MobileDrawer
          criteriaStates={criteriaStates}
          handleCriteriaClick={handleCriteriaClick}
          handleDisplayBeaches={handleDisplayBeaches}
          handleWindSortDisplay={handleWindSortDisplay}
          beaches={beaches}
        />
      ) : (
        <DesktopDrawer
          criteriaStates={criteriaStates}
          handleCriteriaClick={handleCriteriaClick}
          handleWindSortDisplay={handleWindSortDisplay}
          beaches={beaches}
        />
      )}
    </>
  );
};

export default Drawer;
