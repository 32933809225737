import "./Text.css";

import React from "react";
import { isMobile } from "react-device-detect";

interface TextProps {
  type: "title" | "paragraph" | "subtitle";
  text: string;
}

const Text = ({ type, text }: TextProps) => {
  const getTextElement = () => {
    switch (type) {
      case "title":
        return <h1>{text}</h1>;
      case "paragraph":
        return <p>{text}</p>;
      case "subtitle":
        return <h2>{text}</h2>;
      default:
        return null;
    }
  };

  return (
    <div className={`${isMobile ? "text-mobile" : "text-desktop"}`}>
      {getTextElement()}
    </div>
  );
};

export default Text;
